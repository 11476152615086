import { mapState, mapActions } from 'vuex';
import RegisterAssetMaintenance from '@/components/inventory/elements/RegisterAssetMaintenance/index.vue';
import { showError } from '@/helpers/globalHelpers';
import moment from 'moment';
import { spareStatusColor } from '@/helpers/globalValues';

export default {
	name: 'DetailAssignSpareToAsset',
	data() {
		return {
			isLoading: false,
			registerMaintenanceDialog: false,
			headers: [
				{
					text: 'Activo',
					align: 'start',
					sortable: false,
					value: 'asset',
				},
				{
					text: 'Número de serie',
					align: 'start',
					sortable: false,
					value: 'num_serie',
				},
			],
			items: [],
			confirmationTransferDialog: false,
		};
	},
	computed: {
		breadcrumbs() {
			return [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Inventario',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'InventoryHome',
					},
				},
				{
					text: 'Repuestos',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'SparesPermissions',
					},
				},
				{
					text: 'Detalle de Repuesto',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'DetailSpareInventory',
						params: { spareId: this.$route.params.spareId },
					},
				},
				{
					text: 'Detalle de Mantenimiento',
					link: true,
					exact: true,
					disabled: true,
				},
			];
		},

		isBack() {
			return !!this.$route.query.goback;
		},

		...mapState('spareAssign', ['spareAssign']),
	},
	created() {
		this.loadSpareAssignById();
	},
	methods: {
		...mapActions('spareAssign', ['getSpareAssingById', 'cleanSpareAssign']),
		async loadSpareAssignById() {
			this.isLoading = true;
			const { spareAssignmentId } = this.$route.params;
			const { error } = await this.getSpareAssingById(spareAssignmentId);

			if (error) showError(error);
			this.isLoading = false;
		},

		formatDate(dateString) {
			if (!dateString) {
				return '-';
			}
			return moment(dateString).format('DD/MM/YYYY HH:mm');
		},

		// async loadAssetById() {
		//     const { assetId } = this.$route.params;
		//     const { error } = await this.getAssetById(assetId);
		//     if (error) showError(error);
		// },

		registerMaintenance() {
			this.registerMaintenanceDialog = true;
		},

		getStatusColor(status) {
			switch (status) {
				case 'MOVILIZANDO':
					return 'blue accent-4';
				case 'CANCELADO':
					return 'red';
				case 'RECEPCIONADO':
				case 'RECEPCIONADO (*)':
					return 'green';
				case 'RECHAZADO':
					return 'blue-grey';
				default:
					return ''; // color por defecto o podrías retornar algún color en particular
			}
		},
		cancelTransfer() {
			this.$swal({
				text: '¿Estás seguro de cancelar este traslado?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#0039a6',
				cancelButtonColor: 'grey',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Sí, cancelar',
			}).then(async (result) => {
				if (result.isConfirmed) {
					this.$router.push({ name: 'ListTransfer' });
				}
			});
		},
		declineTransfer() {
			this.$swal({
				text: '¿Estás seguro de rechazar este traslado?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#0039a6',
				cancelButtonColor: 'grey',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Sí, rechazar',
			}).then(async (result) => {
				if (result.isConfirmed) {
					this.$router.push({ name: 'ListTransfer' });
				}
			});
		},
		confirmTransfer() {
			this.confirmationTransferDialog = true;
		},
		getStateColor(status) {
			if (status) {
				return spareStatusColor.find((e) => e?.key == status);
			}
			return null;
		},
	},
	beforeDestroy() {
		this.cleanSpareAssign();
	},
	components: {
		RegisterAssetMaintenance,
	},
};
