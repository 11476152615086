<template>
	<DetailAssignSpare />
</template>
<script>
//import HomePermissions from '@/components/permissions/home/index.vue';
import DetailAssignSpare from '@/components/inventory/elements/Spare/DetailAssignSpareToAsset/index.vue';

export default {
	name: 'DetailAssignSparePage',
	components: { DetailAssignSpare },
};
</script>
